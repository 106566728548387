import firebase from 'firebase/app';
import '@firebase/messaging';
import '@firebase/analytics';

let messaging;

const config = {
  apiKey: "AIzaSyAUcEBlSOnZ-rWyp852SNHzu2FVz8tLZ-Q",
  authDomain: "farmacia-daddi.firebaseapp.com",
  databaseURL: "https://farmacia-daddi.firebaseio.com",
  projectId: "farmacia-daddi",
  storageBucket: "farmacia-daddi.appspot.com",
  messagingSenderId: "296521102085",
  appId: "1:296521102085:web:7956f10cedcd3bfae93d6c",
  measurementId: "G-NEFK42LZQH"
};

firebase.initializeApp(config);

if(firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey('BBY1Fz6fxSCu6Ydn23bsruw9T8gepjzjgUgIdaHz7LqI8P3Gf9AWvsQ4BnpLRzQ6OUO2DSDViw98EHssy9gUuvQ');
}

export {
  messaging
};